.textfield-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.textfield-label {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  color: #404040;
}

.textfield-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.textfield-input {
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  font-size: 15px;
  border: 1px solid #c4c2c2;
  border-radius: 5px;
  font-weight: 500;
  color: #0b0a0ad4;
}

.password-toggle-icon {
  position: absolute;
  right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.textfield-input:focus {
  border-color: #6200ea;
}

.required-asterisk {
  color: red;
  margin-left: 2px;
  position: relative;
  top: -1px;
}
