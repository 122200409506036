@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Outfit", sans-serif;
  background-color: var(--body__background);
  font-family: "Outfit", sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

.uploaded-files-list::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: var(--TreeItem__Scrollbar);
  border-radius: 10px;
}

body::-webkit-scrollbar:hover {
  background: var(--TreeItem__Scrollbar_background);
}

.Files-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  width: 65%;
  margin-top: 2vw;
  padding: 20px;
  border: 2px dashed var(--dashed__border);
  height: 490px;
}

.choose-file {
  color: #2992ff;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  border-bottom: 1px solid #1a89fc;
  border: none;
}

.supported-file {
  font-size: 13px;
  font-weight: 500;
  color: #88898a;
  font-family: "Outfit", sans-serif;
  margin-top: 0px;
}

.upload-heading {
  color: black;
  font-size: 22px;
  font-weight: 400;
  font-family: "Belanosima", sans-serif;
}

.drag-drop {
  color: var(--login__container_header);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 25px;
}

.Files-upload-container h2 {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Outfit", sans-serif;
}

form {
  width: 100%;
}

.Files-upload-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 0;
  float: left;
}

.Files-upload-container input[type="file"] {
  margin-bottom: 10px;
}

.uploaded-files-name-list {
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 8px;
  background-color: #f7f7f7;
  height: 500px;
  overflow-y: auto;
  width: 35%;
  margin-top: 2vw;
}

.uploaded-files-name-list h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #6860ee;
  text-align: center;
}

.uploaded-files-name-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.uploaded-files-name-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 8px;
}

.file-icon img {
  width: 24px;
  height: 24px;
}

.file-name {
  flex: 1;
  margin-left: 10px;
}

.delete-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}

.file-drop-container.drag-over {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

.choose-file {
  color: #1890ff;
  cursor: pointer;
}

.indexing button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 25px 10px 25px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #0078ff;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
}

img.status-img {
  width: 15px;
}

.indexing-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--login__container_header);
  padding-bottom: 0px;
  width: 99%;
}

.indexing button :hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 12px;
  position: relative;
  top: 5px;
}

.file-drop-container {
  width: 90%;
  height: 300px;
  margin-bottom: 2vh;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  background-color: var(--file__upload_container);
  border-radius: 5px;
  border: 2px dashed var(--dashed__border);
}

.uploaded-files-notification-container {
  position: relative;
  animation: slideDown 1s ease-out forwards;
  transform: translateY(-100%);
  opacity: 0;
}

.uploaded-files-notification-container.show {
  transform: translateY(0);
  opacity: 1;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.uploaded-files-notification-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  align-items: baseline;
  border: 1px solid #d6d6d6;
  width: 400px;
  float: right;
  margin-bottom: 20px;
  margin-top: 5vh;
}

.uploaded-files-header {
  margin: 0;
  padding: 10px 20px;
  color: #fff;
  background-color: #6860ee;
  width: 100%;
  border-radius: 2px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}

.cancel-icon {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.modal-content form label {
  font-weight: 600;
  margin-bottom: 5px;
  width: 130px;
}

.modal-content form input,
.modal-content form select {
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
}

.modal-name,
.modal-member,
.modal-status {
  display: flex;
  gap: 50px;
  outline: none;
}

input:focus {
  outline: none;
}

.modal-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 20px;
}

.modal-filter {
  display: flex;
  flex-direction: column;
}

.filter-div {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.modal-filter label {
  font-size: large;
  font-weight: 500;
}

.uploaded-files-container .file-list-outer-div {
  width: 700px;
  display: flex;
  justify-content: space-between;
  background: #efefef;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 20px;
  text-align: left;
  float: left;
  column-gap: 20px;
  flex-direction: column;
  row-gap: 20px;
}

.uploaded-files-container .file-list-inner {
  display: flex;
  justify-content: space-between;
}

.uploaded-files-container .file-list-outer-div .file-list-inner-upload {
  display: flex;
  column-gap: 20px;
  align-items: center;
  font-size: 20px;
}

.uploaded-files-container .file-list-outer-div .file-list-inner-upload svg {
  width: 65px !important;
  height: 60px !important;
  color: #004b9e;
}

.upload-section-btn {
  column-gap: 16px;
  display: flex;
  margin-top: 20px;
}

.upload-section-btn button.cancel {
  padding: 6px 20px;
  background-color: transparent;
  color: #88898a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #88898a;
}

.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.maximum {
  color: #004b9e;
}

.full-screen-div .listed-files-container::-webkit-scrollbar {
  width: 10px;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-thumb {
  background: #004e9c62;
  border-radius: 10px;
}

.full-screen-div .listed-files-container::-webkit-scrollbar-thumb:hover {
  background: #004e9c;
}

.resume-upper-container {
  display: flex;
  justify-content: space-between;
}

.main-resume-container {
  padding: 20px 50px;
  height: 100vh;
}

.resume-header-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resume-buttons {
  display: flex;
  gap: 20px;
}

.table-container {
  margin-top: 50px;
}

.global-data-table::-webkit-scrollbar,
.candidate-table::-webkit-scrollbar,
.jobs-table::-webkit-scrollbar,
.clients-table::-webkit-scrollbar,
.styled-scrollbar::-webkit-scrollbar,
.autocomplete-listoption::-webkit-scrollbar,
.sun-editor-editable::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Scrollbar Track Style */
.global-data-table::-webkit-scrollbar-track,
.candidate-table::-webkit-scrollbar-track,
.jobs-table::-webkit-scrollbar-track,
.clients-table::-webkit-scrollbar-track,
.styled-scrollbar::-webkit-scrollbar-track,
.autocomplete-listoption::-webkit-scrollbar-track,
.sun-editor-editable::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f2f2ff;
}

.global-data-table::-webkit-scrollbar-track:hover,
.candidate-table::-webkit-scrollbar-track:hover,
.jobs-table::-webkit-scrollbar-track:hover,
.clients-table::-webkit-scrollbar-track:hover,
.styled-scrollbar::-webkit-scrollbar-track:hover,
.autocomplete-listoption::-webkit-scrollbar-track:hover,
.sun-editor-editable::-webkit-scrollbar-track:hover {
  background-color: #f2f2ff;
}

.global-data-table::-webkit-scrollbar-track:active,
.candidate-table::-webkit-scrollbar-track:active,
.jobs-table::-webkit-scrollbar-track:active,
.clients-table::-webkit-scrollbar-track:active,
.styled-scrollbar::-webkit-scrollbar-track:active,
.autocomplete-listoption::-webkit-scrollbar-track:active,
.sun-editor-editable::-webkit-scrollbar-track:active {
  background-color: #f2f2ff;
}

/* Scrollbar Thumb Style */
.global-data-table::-webkit-scrollbar-thumb,
.candidate-table::-webkit-scrollbar-thumb,
.jobs-table::-webkit-scrollbar-thumb,
.clients-table::-webkit-scrollbar-thumb,
.styled-scrollbar::-webkit-scrollbar-thumb,
.autocomplete-listoption::-webkit-scrollbar-thumb,
.sun-editor-editable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1b52a729;
}

.global-data-table::-webkit-scrollbar-thumb:hover,
.candidate-table::-webkit-scrollbar-thumb:hover,
.jobs-table::-webkit-scrollbar-thumb:hover,
.clients-table::-webkit-scrollbar-thumb:hover,
.styled-scrollbar::-webkit-scrollbar-thumb:hover,
.autocomplete-listoption::-webkit-scrollbar-thumb:hover,
.sun-editor-editable::-webkit-scrollbar-thumb:hover {
  background-color: #1b52a742;
}

.global-data-table::-webkit-scrollbar-thumb:active,
.candidate-table::-webkit-scrollbar-thumb:active,
.jobs-table::-webkit-scrollbar-thumb:active,
.clients-table::-webkit-scrollbar-thumb:active,
.styled-scrollbar::-webkit-scrollbar-thumb:active,
.autocomplete-listoption::-webkit-scrollbar-thumb:active,
.sun-editor-editable::-webkit-scrollbar-thumb:active {
  background-color: #1b52a740;
}
.status-invited {
  background-color: #b3d7ff;
  color: #0066cc;
  padding: 5px 20px;
  border-radius: 5px;
}

.status-activated {
  background-color: #c3f3d7;
  color: #0f844d;
  padding: 5px 10px;
  border-radius: 5px;
}

.status-pending {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 5px 15px;
  border-radius: 5px;
}

.skeleton-row {
  display: flex;
  align-items: center;
}

.skeleton-row > div {
  flex: 1;
}

.filters {
  display: flex;
  gap: 10vw;
}

.filter-checkboxes {
  display: flex;
  flex-direction: column;
}

.filter-checkboxes label {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.table-span{
    display: "flex";
    width: "fit-content";
    white-space: "break-spaces"
}

/* Responsive */

/* @media (max-width: 700px) {
  /*Tab-landscape mode*/

/* .supported-file {
    font-size: 10px;
  }
  .drag-drop {
    font-size: 12px;
    margin-top: 28px;
  }
  .indexing button {
    font-size: 12px;
    width: 30% !important;
  }
} */

/* @media (max-width: 400px) { */
/*Smart-phones*/
/* .Files-upload-container h2 {
    font-size: 15px;
  }
} */

/* @media (max-width: 400px) {
  .choose-file {
    font-size: 10px !important;
  }
  .upload-section-btn {
    column-gap: 15px;
  }
  .sidebar {
    flex: 0 0 auto;
    min-width: 200px;
    max-width: 356px;
  }
} */

/* @media (max-width: 500px) {
  .indexing button {
    width: 90px;
    height: 30px;
    font-size: 12px;
  }
} */
.file-upload-outer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  column-gap: 20px;
  height: auto;
}

div#root {
  /* height: 100vh; */
  position: relative;
}

.file-upload-outer .Files-list {
  opacity: 0;
}

.file-upload-outer .Files-list {
  height: 26vw;
  overflow-y: scroll;
  overflow-x: scroll;
}

.file-upload-outer.active .Files-list {
  opacity: 1;
}

.header {
  margin-left: 140px;
  padding-top: 15px;
  margin-top: 30px;
  border-radius: 10px;
}

.footer {
  display: flex;
  text-align: center;
  justify-content: center;
  display: none;
}

img.vybot-logo {
  width: 175px;
  text-align: center;
  margin: auto;
  display: flex;
}

h2.upload-heading {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

h2.upload-heading img.file-icon {
  width: 30px;
}

.file-upload-outer.active .header {
  background-color: #ffffff;
}

.header h5 {
  padding: 15px;
  border-bottom: 1px solid #ebebeb;
}

.file-upload-outer .Files-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.file-upload-outer .Files-list::-webkit-scrollbar {
  border-radius: 10px;
}

.file-upload-outer .Files-list::-webkit-scrollbar-thumb {
  background: var(--TreeItem__Scrollbar);
  border-radius: 10px;
}

.file-upload-outer .Files-list::-webkit-scrollbar:hover {
  background: var(--TreeItem__Scrollbar_background);
}

.file-upload-outer .indexing-table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.file-upload-outer .indexing-table-wrapper::-webkit-scrollbar {
  border-radius: 10px;
}

.file-upload-outer .indexing-table-wrapper::-webkit-scrollbar-thumb {
  background: var(--TreeItem__Scrollbar);
  border-radius: 10px;
}

.file-upload-outer .indexing-table-wrapper::-webkit-scrollbar:hover {
  background: var(--TreeItem__Scrollbar_background);
}

.file-upload-outer .Documents-container {
  height: 94%;
  border-radius: 10px;
  background: var(--file__upload_container);
  padding: 20px;
  cursor: pointer;
  margin: 0;
}

.file-upload-outer .Documents-container .bot-logo {
  padding-top: 10px;
}

.file-upload-outer .Documents-container .bot-logo img {
  text-align: center;
}

.Logout-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.Logout-container img {
  position: absolute;
  left: auto !important;
  right: 0;
  top: -26px;
}

.file-upload-outer .file-container {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
  border-bottom: 1px solid #dadada;
}

.file-upload-outer .file-container h5.documents {
  color: var(--login__container_header);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.indexing {
  width: 95%;
  height: 31vw;
  border-radius: 10px;
  background: var(--file__upload_container);
  padding: 5px 25px;
  margin-top: 24px;
  cursor: pointer;
}

.indexing-title h4 {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 25px 0px 10px 0px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dadada;
}

.indexing-table-wrapper {
  max-height: 25vw;
  overflow-y: scroll;
}

.indexing-status-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  position: relative;
}

.file-upload-tbody tr {
  border: 2px solid #eaeaeaa3;
  background-color: var(--file__upload_container);
  color: var(--login__container_header);
}

.indexing-status-table th {
  padding: 16px 10px;
}

.indexing-status-table td {
  padding: 1px 10px;
  font-family: "Outfit", sans-serif;
  width: 17vw;
  font-size: 14px;
}

.indexing h4.indexing-title {
  padding-bottom: 20px;
  font-size: 15px;
  font-family: "Outfit", sans-serif;
  margin-bottom: 0px;
  color: var(--login__container_header);
  font-weight: 500;
}

.status-container,
.file-entry {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.indexing-status-table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--file__upload_container);
  color: var(--login__container_header);
  font-size: 13px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
}

.indexing-status-table .indexing-title {
  font-family: "Outfit", sans-serif;
  font-size: 16px;
}

.All-uploaded-files
  .files-div
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 14px;
  color: var(--login__container_header);
}

.All-uploaded-files
  .files-div
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ca0000;
  border-radius: 50%;
  font-size: 14px;
}

.All-uploaded-files .files-div p {
  width: 100%;
  margin: 7px 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--login__container_header);
  font-size: 12px;
}

.file-upload-outer .logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0 !important;
  cursor: pointer;
}

.Documents-container img.logout-button {
  width: 45px;
  height: 45px;
  float: right;
}

.file-upload-outer .Logout-container {
  margin-top: 4px;
}

table :hover {
  background-color: none !important;
}

.information-page {
  width: 100%;
  height: 100%;
  background: var(--file__upload_container);
  margin-top: 20px;
  border-radius: 5px;
}

.information-page h4 {
  font-size: 15px;
  border-bottom: 1px solid var(--login__container_header);
  width: 95%;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
  color: var(--login__container_header);
  margin: 0 auto;
  padding: 15px 0px 10px 0px;
}

.information-page .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.text-wrapper {
  display: flex;
  align-items: center;

  margin-bottom: -14px;
}

.text-wrapper p {
  margin-left: 10px;
}

.information-page
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #b638fac7;
}

.information-page img {
  width: 25%;
  height: auto;
  display: table;
  margin: 0 auto;
}

.folder-container span.foldername {
  flex: 0.9 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20px;
  white-space: nowrap;
  color: var(--login__container_header);
  font-size: 13px;
}

.folder-container .folder-inner {
  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 0px;
  justify-content: space-between;
  padding: 10px 0px 5px 0px;
  border-bottom: 1px solid #e6e6e6;
  color: var(--login__container_header);
}

.folder-contents .files-div {
  color: var(--login__container_header);
}

.no-files {
  text-align: center;
  color: red;
}

.indexing .file-entry img.file-img {
  width: 21px;
}

.file-drop-container label.file-upload-label {
  display: flex;
  align-items: center;
  flex-direction: column;
  column-gap: 10px;
}

.information-page .text-wrapper img {
  width: 11px;
}

.information-page .text-wrapper p {
  margin-left: 24px !important;
  font-family: "Outfit", sans-serif;
  color: var(--login__container_header);
  font-size: 14px;
}

.information-page .text-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.table-files-div {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #e4dfdf;
}

.table-folder-container
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  position: absolute;
  right: 2vw;
}

.table-folder-container .table-folder-inner {
  display: flex;
  align-items: center;
  gap: 4px;
}

tr.table-folder-content {
  background: var(--table__folder_container);
  color: var(--table__folder_Color);
}

.Files-list .css-1g86id8-MuiTreeItem-content .MuiTreeItem-iconContainer svg {
  font-size: 18px;
  fill: var(--login__container_header);
}

.css-1g86id8-MuiTreeItem-content.MuiTreeItem-content.Mui-expanded.Mui-selected {
  background: var(--TreeItem__background);
  color: var(--TreeItem__color);
}

.Files-list .css-1g86id8-MuiTreeItem-content {
  /* padding: 0 8px; */
  width: 92%;
}

.Toogle-button {
  width: 4vw !important;
  /* height: 4vw !important; */
}

/* @media only screen and (max-width: 991px) {
  .file-upload-outer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    height: auto;
  }
  .indexing button {
    width: 20%;
  }
  .indexing {
    height: 85vh;
  }
  .indexing-table-wrapper {
    max-height: 45vw;
  }
} */
/* @media only screen and (max-width: 450px) {
  .file-upload-outer .Documents-container {
    width: 60%;
    height: auto;
    margin: 0 auto;
  }
  .indexing-table-wrapper {
    max-height: 95vw;
  }
} */
/* @media only screen and (min-width: 1400px) and (max-width: 3100px) {
  .file-upload-outer {
    height: 100%;
  }
} */
.Login-footer {
  margin-top: 4vw;
  margin-left: 20px;
  font-size: 12px;
}

/* large screen */
/* @media only screen and (min-width: 4501px) and (max-width: 6000px) { */
/*25*/
/* } */

/* @media only screen and (min-width: 3100px) and (max-width: 4500px) { */
/*33%*/
/* .file-drop-container {
    width: 95%;
  }
  .indexing {
    width: 92%;
    height: 26vw;
  }
  .information-page {
    width: 95%;
    height: 65%;
  }
  .file-upload-outer {
    height: 90%;
    max-width: 3500px;
    margin: 0 auto;
  }
  .information-page .text-wrapper p {
    font-size: 25px;
  }
  .information-page img {
    width: 18%;
  }
  .file-upload-outer .Documents-container {
    height: 80%;
    width: 100%;
  }
  .bot-logo img {
    width: 54%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
    font-size: 24px;
  }
  .information-page h4 {
    font-size: 25px;
  }
  .footer-container p.copyright {
    font-size: 22px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 18px;
    font-size: 12px;
  }
  .indexing-status-table th {
    font-size: 18px;
  }
  .All-uploaded-files .files-div p {
    font-size: 17px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 17px;
  }
  .file-container h5.clear {
    font-size: 17px !important;
  }
  .search input[type="search"] {
    font-size: 20px;
  }
} */

/* @media only screen and (min-width: 2701px) and (max-width: 3100px) { */
/*50%*/
/* .file-drop-container {
    width: 95%;
  }
  .indexing {
    width: 92%;
    height: 26vw;
  }
  .indexing-table-wrapper {
    max-height: 22vw;
  }
  .file-upload-outer .Files-list {
    height: 23vw;
  }
  .information-page {
    width: 95%;
    height: 65%;
  }
  .file-upload-outer {
    height: 90%;
    max-width: 2300px;
    margin: 0 auto;
  }
  .information-page .text-wrapper p {
    font-size: 25px;
  }
  .information-page img {
    width: 18%;
  }
  .file-upload-outer .Documents-container {
    height: 89%;
    width: 100%;
  }
  .bot-logo img {
    width: 54%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
    font-size: 24px;
  }
  .information-page h4 {
    font-size: 25px;
  }
  .footer-container p.copyright {
    font-size: 22px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 18px;
    font-size: 12px;
  }
  .indexing-status-table th {
    font-size: 18px;
  }
  .All-uploaded-files .files-div p {
    font-size: 17px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 17px;
  }
  .file-container h5.clear {
    font-size: 17px !important;
  }
  .search input[type="search"] {
    font-size: 19px;
  }
} */

/* @media only screen and (min-width: 2001px) and (max-width: 2700px) { */
/*67%*/
/* .file-upload-outer {
    height: 95%;
    max-width: 1800px;
    margin: 0 auto;
  }
  .indexing {
    width: 96%;
  }
  .indexing-table-wrapper {
    max-height: 22vw;
  }
  .file-upload-outer .Files-list {
    height: 23vw;
  }
  .information-page .text-wrapper p {
    font-size: 21px;
  }
  .information-page img {
    width: 20%;
  }
  .file-upload-outer .Documents-container {
    height: 95%;
    width: 90%;
  }
  .bot-logo img {
    width: 54%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
    font-size: 22px;
  }
  .information-page h4 {
    font-size: 23px;
  }
  .footer-container p.copyright {
    font-size: 20px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 18px;
    font-size: 10px;
  }
  .indexing-status-table th {
    font-size: 17px;
  }
  .All-uploaded-files .files-div p {
    font-size: 16px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 16px;
  }
  .file-container h5.clear {
    font-size: 16px !important;
  }
  .search input[type="search"] {
    font-size: 18px;
  }
} */

/* @media only screen and (min-width: 1801px) and (max-width: 2000px) { */
/*75%*/
/* .file-upload-outer {
    height: 97%;
    max-width: 1750px;
    margin: 0 auto;
  }
  .indexing-table-wrapper {
    max-height: 21vw;
  }
  .file-upload-outer .Files-list {
    height: 22vw;
  }
  .information-page .text-wrapper p {
    font-size: 19px;
  }
  .information-page img {
    width: 20%;
  }
  .file-upload-outer .Documents-container {
    height: 95%;
    width: 90%;
  }
  .bot-logo img {
    width: 54%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
    font-size: 20px;
  }
  .information-page h4 {
    font-size: 22px;
  }
  .footer-container p.copyright {
    font-size: 17px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 19px;
    font-size: 18px;
  }
  .indexing-status-table th {
    font-size: 16px;
  }
  .All-uploaded-files .files-div p {
    font-size: 15px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 15px;
  }
  .file-container h5.clear {
    font-size: 15px !important;
  }
  .search input[type="search"] {
    font-size: 17px;
  }
} */

/* @media only screen and (min-width: 1601px) and (max-width: 1800px) { */
/*80%*/
/* .information-page .text-wrapper p {
    font-size: 17px;
  }
  .indexing-table-wrapper {
    max-height: 21vw;
  }
  .file-upload-outer .Files-list {
    height: 22vw;
  }
  .information-page img {
    width: 20%;
  }
  .file-upload-outer .Documents-container {
    height: 95%;
    width: 90%;
  }
  .bot-logo img {
    width: 54%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
    font-size: 18px;
  }
  .information-page h4 {
    font-size: 20px;
  }
  .footer-container p.copyright {
    font-size: 15px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 19px;
    font-size: 17px;
  }
  .indexing-status-table th {
    font-size: 15px;
  }
  .All-uploaded-files .files-div p {
    font-size: 14px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 14px;
  }
  .file-container h5.clear {
    font-size: 14px;
  }
  .search input[type="search"] {
    font-size: 16px;
  }
} */

/* @media only screen and (min-width: 1517px) and (max-width: 1600px) { */
/*90%*/
/* .information-page {
    width: 100%;
    max-height: 60%;
  }
  .indexing-table-wrapper {
    max-height: 21vw;
  }
  .file-upload-outer .Files-list {
    height: 22vw;
  }
  .information-page .text-wrapper p {
    font-size: 15px;
  }
  .information-page img {
    width: 22%;
  }
  .file-upload-outer .Documents-container {
    height: 95%;
  }
  .bot-logo img {
    width: 52%;
    height: auto;
  }
  .drag-drop {
    line-height: 28px;
  }
  .information-page h4 {
    font-size: 17px;
  }
  .footer-container p.copyright {
    font-size: 15px;
  }
  .indexing h4.indexing-title {
    padding-bottom: 19px;
    font-size: 16px;
  }
  .indexing-status-table th {
    font-size: 14px;
  }
  .All-uploaded-files .files-div p {
    font-size: 13px;
  }
  .file-upload-outer .file-container h5.documents {
    font-size: 13px;
  }
  .file-container h5.clear {
    font-size: 13px;
  }
  .search input[type="search"] {
    font-size: 16px;
  }
} */

.file-upload-outer {
  padding: 20px;
}

.uploaded-files-list {
  padding-left: 0;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  min-height: auto;
  max-height: 100px;
  overflow-y: auto;
}

.uploaded-files-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.progress-div {
  position: relative;
}
.progress-div svg{
  position: absolute;
  top: 0;
  left: -30px;
}
.progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
  width: 100px;
  height: 10px;
  margin-left: 10px;
}

.progress-bar-inner {
  background-color: #6a5acd;
  height: 100%;
  border-radius: 5px;
}

.upload-status {
  color: green;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
