@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
.footer-container {
  background-color: var(--file__upload_container);
  color: var(--Footer__color);
  /* padding: 10px 13px; */
  font-family: "outfit", sans-serif;
  margin-top: 15px;
  border-top: 2px solid #efefef;
  /* position: fixed;
  bottom: 0;
  width: 100%; */
}

.footer-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.copyright-container {
  display: table;
  margin: 0 auto;
}

.footer-logo {
  height: 40px;
  vertical-align: middle;
  margin-left: 5px;
}
