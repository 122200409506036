.theme-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;
  height: auto;
}

.theme-button .Darkicon {
  width: 45px !important;
  height: 45px !important;
}
.chat-bot-outerdiv .Darkicon {
  padding-top: 44px;
  z-index: 99;
}

.theme-button .Lighticon {
  width: 45px !important;
  height: 45px !important;
}
.chat-bot-outerdiv-toogle .theme-button .Lighticon {
  margin-top: 44px;
  z-index: 99;
}
.chat-bot-outerdiv .Lighticon {
  padding-top: 44px;
  z-index: 99;
}
.chat-bot-outerdiv-toogle img.Darkicon {
  margin-top: 44px;
  z-index: 99;
}
