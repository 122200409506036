.dashboard-container {
  padding: 20px;
}
.dashboard-header-container {
  margin: 3px 7px;
}
.chart-section {
  display: flex;
  justify-content: space-around;
}

.year-chart-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  width: 70%;
  height: fit-content;
}

.success-chart-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  width: 30%;
  height: fit-content;
}
.year-chart-container:hover {
  transform: translateY(-5px);
}
.success-chart-container:hover {
  transform: translateY(-5px);
}
.chart-header {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.chart-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #777;
}

.user-panel-outer {
  background-image: url("../Images/dashboard\ -bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 120px;
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  color: white;
  text-align: center;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-panel-outer h2 {
  font-size: 24px;
  margin: 10px 0;
  color: #f3f3f3;
}

.user-panel-outer h5 {
  font-size: 16px;
  margin: 5px 0;
  color: #ddd;
}
.user-plan-outer {
  display: flex;
  gap: 20px;
}
.current-plan-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 30%;
}

.current-plan-container:hover {
  transform: scale(1.02);
}

.current-plan-content {
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: space-between;
}

.current-plan-content h3 {
  font-size: 22px;
  margin: 0;
  color: #ffffff;
}

.current-plan-content p {
  font-size: 16px;
  margin: 5px 0 0;
  color: #f3f3f3;
}

.current-plan-content p span {
  font-weight: bold;
  color: #ffd700;
}

img.vector-img {
  width: 50%;
  position: relative;
  top: -31px;
  height: 12em;
  left: 15px;
}
.details-list-container {
  display: flex;
  gap: 10px;
  height: auto;
  margin-top: 20px;
}
.text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-container {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #ffed58;
  padding: 10px;
  border-radius: 5px;
  background: #f7e70469;
  color: #e3650d;
  font-size: 16px;
  font-weight: 500;
}

img.credit-card-img {
  width: 3%;
}

img.grown-img {
  width: 11%;
}

.plan-text-container {
  display: flex;
  gap: 10px;
}
.subscriber-list {
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.subscriber-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  padding: 0;
}

.subscriber-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #f8f9fa;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subscriber-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.icon {
  width: 24px;
  height: 24px;
}

.subscriber-info {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 12px;
  color: #757575;
}

.value {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

/* ReminderList css */
.reminder-list {
  padding: 0 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #ffffff;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reminder-list h3 {
  margin-bottom: 16px;
  color: #333;
}

.reminder-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.reminder-card {
  flex: 1 1 calc(50% - 16px);
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 12px;
  border-left: 4px solid;
}

.reminder-card .icon {
  width: 40px;
  height: 40px;
}

.reminder-card .reminder-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.reminder-card .title {
  font-weight: bold;
  font-size: 1rem;
  color: #555;
}

.reminder-card .due-date {
  font-size: 0.9rem;
  color: #888;
}

.reminder-card .type {
  font-size: 0.85rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  color: white;
}

.reminder-card.urgent {
  border-left-color: #ff4c4c;
}

.reminder-card.urgent .type {
  background-color: #ff4c4c;
  width: fit-content;
}

.reminder-card.important {
  border-left-color: #ffa500;
}

.reminder-card.important .type {
  background-color: #ffa500;
  width: fit-content;
}

.reminder-card.critical {
  border-left-color: green;
}

.reminder-card.critical .type {
  background-color: green;
  width: fit-content;
}
