.container {
  display: flex;
  height: 100vh;
  border: 1px solid #efefef;
  margin: 2.5vw;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 0;
}
.setting-header-container {
  margin: 20px 35px;
}

.sidebar {
  width: 250px;
  background-color: #f0f0ff;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}

.tab {
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tab:hover {
  background-color: #e0e0ff;
}

.active {
  background-color: #d0d0ff;
  font-weight: bold;
  color: #3134b5;
}

.content-area {
  flex: 1;
  padding: 30px;
  background-color: #ffffff;
  overflow-y: scroll;
}
.subscription-content {
  margin: 30px 0;
}
.content {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  margin-top: 0;
}

.fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

.button-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
}

.integration-button-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
  margin-bottom: 1em;
}
/* Subscripition page css */
:root {
  --primary-fw: 700;
  --primary-fs: 15px;
  --primary-linear-gradient: hsl(298, 97%, 46%), hsl(237, 63%, 64%);
  --neutral-very-light-grayish-blue: hsl(240, 78%, 98%);
  --neutral-light-grayish-blue: hsl(234, 14%, 74%);
  --neutral-grayish-blue: hsl(233, 13%, 49%);
  --neutral-dark-grayish-blue: hsl(232, 13%, 33%);
  --neutral-white: hsl(0, 0%, 100%);
}

.PricingApp {
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
  font-family: "Montserrat", sans-serif;
  font-weight: var(--primary-fw);
  font-size: var(--primary-fs);
  display: grid;
  place-items: center;
  /* background-color: var(--neutral-very-light-grayish-blue); */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 375px 658px;
  z-index: 999;
}

.PricingApp::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 353px;
  width: 100%;
  height: 304px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

button {
  font-family: "Montserrat", sans-serif;
  font-weight: var(--primary-fw);
  font-size: var(--primary-fs);
}

.PricingApp .price-switch {
  --border-radius: 10em;
  display: block;
  width: 50px;
  height: 25px;
  border-radius: var(--border-radius);
  position: relative;
  cursor: pointer;
}

.PricingApp .price-checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.PricingApp .switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 25px;
  border-radius: var(--border-radius);
  background-image: linear-gradient(to right, var(--primary-linear-gradient));
}

.PricingApp .switch-slider:is(:hover, :focus-visible) {
  opacity: 0.7;
}

.PricingApp .switch-slider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 20px;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--neutral-very-light-grayish-blue);
  transform: translateX(calc(100% + 2px));
  transition: 0.3s ease;
}

.PricingApp .price-checkbox:checked + .switch-slider::before {
  transform: translateX(0%);
}
/*------*/

.PricingApp .app-container {
  width: min(90%, 70rem);
  margin-top: -6em;
}

.PricingApp header .header-topic {
  text-align: center;
  color: var(--neutral-dark-grayish-blue);
  margin-top: 1.5em;
}

.PricingApp header > .header-row {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.PricingApp .pricing-cards {
  /* margin-top: 4em; */
  min-height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

@media screen and (max-width: 800px) {
  .PricingApp {
    padding-block: 4em;
  }
  .PricingApp .pricing-cards {
    flex-direction: column;
    gap: 2em;
  }
}

.notification-li {
  margin-bottom: 20px;
}

.checkbox-contanier {
  display: flex;
  gap: 30px;
  margin-top: 2em;
}

.api-integration-guidelines {
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
  background: #f0f0fe;
}

.sub-header {
  color: #2f37e7e0;
}

.api-details-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1.5rem;
}

.api-details-list li {
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border-left: 4px solid #6a68fb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.api-details-list li:not(:last-child) {
  margin-bottom: 1rem;
}

.api-details-list li strong {
  color: #6a68fb;
}

.api-details-list li em {
  font-style: italic;
  color: #333;
}

.api-details-list li .icon {
  font-size: 1.5rem;
  color: #6a68fb;
  margin-right: 0.75rem;
  margin-top: 0.25rem;
}

.api-details-list li p {
  margin: 0;
  color: #333;
}

.api-details-list li p {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (max-width: 768px) {
  .api-details-list li {
    flex-direction: column;
  }

  .api-details-list li .icon {
    margin-bottom: 0.5rem;
  }
}

/* Documentation Tab Design */

.documentation-section {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.documentation-section h3 {
  font-size: 1.3em;
  color: #6a68fb;
}

.documentation-section h4 {
  font-size: 1.2em;
  color: #666;
}

.documentation-section p {
  font-size: 1em;
  color: #444;
  line-height: 1.6;
}

.code-block {
  background-color: #f3f3f3;
  border-left: 4px solid #6a68fb;
  padding: 10px;
  color: #333;
  font-family: Consolas, "Courier New", monospace;
  overflow-x: auto;
  white-space: pre;
  border-radius: 4px;
}

.outer-select-container {
  padding: 10px;
  border-radius: 5px;
  background: #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  margin-bottom: 1em;
}

span.option-selector {
  padding: 5px 9em;
  border-radius: 5px;
  cursor: pointer;
}

.active-option-selector {
  border: 1px solid rgb(231, 229, 229);
  background-color: white;
  color: black;
}
.request-samples-container {
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section-title {
  font-size: 1.5rem;
  color: #6a68fb;
  margin-bottom: 1rem;
}

.intro-text {
  margin-bottom: 2rem;
  font-size: 1rem;
  color: #555;
}

.sample-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.sample-card {
  width: 40%;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.sample-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.language-icon {
  font-size: 2rem;
  color: #6a68fb;
  margin-right: 0.5rem;
}

.sample-card h4 {
  font-size: 1.2rem;
  color: #333;
}

.sample-card-content {
  font-size: 0.9rem;
  color: #666;
}
