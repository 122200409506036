.global-data-table .rdt_TableHeadRow {
  background-color: #f2f2ff;
}
.rdt_TableHead {
  z-index: 99 !important;
}
.global-data-table .rdt_TableCol {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.global-data-table .rdt_TableBody .rdt_TableRow {
  position: relative;
}

.global-data-table .rdt_TableBody .rdt_TableCell {
  position: static !important;
  z-index: 1;
}

.delete-outer p {
  color: #7979e9;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.yes-button {
  background-color: rgb(42, 167, 57);
}

.no-button {
  background-color: rgb(240, 36, 36);
}

.yes-button,
.no-button {
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.global-data-table {
  border: 1px solid #e3e3ea;
}
.right-header .css-jcn4dz-MuiBadge-badge {
  background-color: #ff0400a3 !important;
  color: #fff !important;
  top: 6px !important;
  right: 1px !important;
}
