@import url("https://fonts.googleapis.com/css2?family=Belanosima&family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Outfit&display=swap");
.home-button {
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  color: #e0e0e5;
  font-weight: 600;
  background: #361e7d;
  width: 12vw;
  height: 3vw;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}
.home-button:hover {
  background: #1212f3;
  font-size: 20px;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video {
  display: flex;
  align-items: center;
  width: 50%;
  height: 50%;
  object-fit: cover;
}
.video-1 {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  object-fit: cover;
}
.video-2 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  margin-top: 14%;
  padding: 100px;
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #361e7d;
}
