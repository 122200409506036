@import url("https://fonts.googleapis.com/css2?family=Belanosima&family=Outfit&display=swap");
.login-page {
  display: flex;
  align-items: flex-start;
    height: 100vh;
  background-color: var(--body__background);
  font-family: "Outfit", sans-serif;
  overflow: auto;
}

.login-container {
  background-color: var(--body__background);
  border-radius: 8px;
  width: 100%;
}
.login-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-container {
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 0 auto;
}
.login-container .input-container {
  margin-top: 10px;
}
.login-container h2 {
  text-align: center;
  color: var(--header__color);
  font-family: Outfit;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.login-container img {
  width: 20px;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 16px;
}
.login-container h5 {
  cursor: pointer;
  color: var(--login__sideheading);
  font-weight: 400;
  padding: 0px 20px;
  text-align: right;
  margin: 0;
  display: flex;
  align-items: center;
}
.login-container h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--login__container_header);
  margin: 20px 0px 3px 0px;
}

.login-container span {
  color: var(--login__header);
  cursor: pointer;
}
.learn-more {
  color: #439cff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.login-container input {
  width: 100%;
  padding: 13px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0;
  font-family: "Outfit", sans-serif;
}
.Login-with-Google {
  width: 369px;
  height: 30px;
  font-family: "Outfit", sans-serif;
  background: white;
  padding: 4px 10px;
  border: none;
  top: 15px;
  left: 227px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  column-gap: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.Login-with-Google img {
  width: 25px !important;
  height: 25px;
  top: auto;
  left: auto;
  display: block;
  margin: initial;
  cursor: pointer;
  padding: 0px;
}

.Login-with-Google button.google-button {
  background: none;
  border: none;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
}

.Login-with-Microsoft {
  width: 369px;
  height: 40px;
  font-family: "Outfit", sans-serif;
  background: white;
  padding: 0px 10px;
  border: none;
  top: 15px;
  left: 227px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  column-gap: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 30px;
}
.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login-with-Microsoft img {
  width: 20px;
  height: 20px;
  top: auto;
  left: 14px;
  top: auto;
  display: block;
  margin: initial;
  cursor: pointer;
  padding: 0px 0px 0px 14px;
}

.Login-with-Microsoft button.google-button {
  background: none;
  border: none;
}

button.Microsoft-button {
  font-family: "Outfit", sans-serif;
  background: white;
  border: none;
  cursor: pointer;
}

.login-button {
  width: 386px;
  padding: 10px;
  margin-bottom: 10px;
  color: #f1efef;
  cursor: pointer;
  margin-top: 25px;
  border-radius: 5px;
  background: linear-gradient(147deg, #2b8fff 0%, #e510ff 100%);
  height: 42px;
  border: none;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
}
.login-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.side-img {
  width: 100%;
}
.side-img.login-height {
  height: 100vh;
}

.side-img.signup-height {
  height: 110vh;
}
.head-text {
  position: relative;
  width: 50%;
    background: url('../Images//Rectangle-2.png');
      background-size: cover;
      /* Ensures the image covers the entire element */
      background-position: center;
      /* Centers the image */
      background-repeat: no-repeat;
}
.text-on-image {
  position: absolute;
  left: 7%;
  top: 3%;
  color: white;
  width: 88%;
  font-size: 13px;
  font-weight: 100;
  letter-spacing: 1px;
  display: grid;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: baseline;
  justify-items: start;
}

.horizontal-section-outer {
  display: flex;
  justify-content: center;
}
.horizontal-section-outer p {
  position: relative;
  color: var(--header__color);
}
.horizontal-section-outer p::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 1px;
  background: var(--header__color);
  top: 9px;
  left: 33px;
}
.horizontal-section-outer p::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 1px;
  background: var(--header__color);
  top: 9px;
  right: 33px;
}

.side-img img {
  height: 100%;
  width: 100%;
}
p.side-content-text {
  padding: 20px 0px 51px 0px;
  color: #fff;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  position: static;
  line-height: 22px;
  width: 100%;
  height: 100%;
}
.error-message {
  color: red;
  font-size: 12px;
  flex-direction: column-reverse;
  align-content: stretch;
}
.eye-icon {
  position: absolute;
  right: 6px;
  top: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
}
.login-container .input-container img {
  top: 2px;
  left: 270px;
}

.password-div {
  position: relative;
}
.login-page img.side-logo {
  width: 50%;
  height: auto;
  position: relative;
  top: 12px;
  left: 20%;
}

@media screen and (max-width: 800px) {
  .login-page {
    display: inline;
  }
  .head-text {
    width: 100%;
    height: auto;
  }
  .side-img {
    width: 100%;
    height: 600px;
  }
  .login-page img.side-logo {
    width: 40%;
    left: 25%;
  }
}
@media only screen and (min-width:800px) and (max-width: 1050px) {
  p.side-content-text {
 font-size: 12px;
  }
}
@media screen and (max-width: 450px) {
  .login-button {
    width: 320px;
  }
  .Login-with-Google {
    width: 300px;
  }
  .Login-with-Microsoft {
    width: 300px;
  }
  .input-container {
    width: 294px;
  }
}
@media screen and (max-width: 400px) {
  .login-button {
    width: 280px;
  }
  .Login-with-Google {
    width: 260px;
  }
  .Login-with-Microsoft {
    width: 260px;
  }
  .input-container {
    width: 254px;
  }
  .horizontal-section-outer p::before {
    width: 90px;
  }
  .horizontal-section-outer p::after {
    width: 90px;
  }
}
.login-container .Toogle-button {
  margin-top: -1vw;
}

@media only screen and (min-width:4501px) and (max-width: 6000px) {
  /*25*/

}

@media only screen and (min-width:3100px) and (max-width: 4500px) {
  /*33%*/

}

@media only screen and (min-width:2701px) and (max-width: 3100px) {
  /*50%*/

}

@media only screen and (min-width:2001px) and (max-width: 2700px) {
  /*67%*/

}

@media only screen and (min-width: 1801px) and (max-width: 2000px) {
  /*75%*/
  .text-on-image {
    font-size: 17px;
  }
  .learn-more {
    font-size: 17px;
 }
 p.side-content-text {
  font-size:18px;
 }
 .login-container h5 {
  font-size: 17px;
 }
 .login-container h2 {
  font-size: 27px;
 }
 .login-container h4 {
  font-size: 20px;
 }
 .login-container input {
  padding: 16px;
  font-size: 17px;
  width: 97%;
}
.login-button {
 font-size: 20px;
  height: 46px;
}
.Login-with-Google button.google-button {
 font-size: 17px;
 height: 34px;
}
button.Microsoft-button {
 font-size: 17px; 
 height: 34px;
}
}

@media only screen and (min-width:1601px) and (max-width: 1800px) {
  /*80%*/
  .text-on-image {
    font-size: 15px;
  }
  .learn-more {
    font-size: 15px;
 }
 p.side-content-text {
  font-size:16px;
 }
 .login-container h5 {
  font-size: 15px;
 }
 .login-container h2 {
  font-size: 25px;
 }
 .login-container h4 {
  font-size: 18px;
 }
 .login-container input {
  padding: 15px;
  font-size: 15px;
  width: 98%
}
.login-button {
 font-size: 18px;
  padding: 12px;
  height: 44px;
}
.Login-with-Google button.google-button {
 font-size: 15px;
 height: 32px;
}
button.Microsoft-button {
 font-size: 15px; 
 height: 32px;
}
}

@media only screen and (min-width:1517px) and (max-width: 1600px) {
  /*90%*/
  .text-on-image {
    font-size: 14px;
  }
  .learn-more {
    font-size: 15px;
 }
 p.side-content-text {
  font-size:15px;
 }
 .login-container h5 {
  font-size: 14px;
 }
 .login-container h2 {
  font-size: 24px;
 }
 .login-container h4 {
  font-size: 17px;
 }
 .login-container input {
  padding: 14px;
  font-size: 14px;
  width: 99%;
}
.login-button {
 font-size: 17px;
  padding: 11px;
  height: 43px;
}
.Login-with-Google button.google-button {
 font-size: 14px;
 height: 31px;
}
button.Microsoft-button {
 font-size: 14px; 
 height: 31px;
}

}
