.account-overall-container {
  display: flex;
  margin: 25px;
  gap: 15px;
}

.profile-update {
  width: 20%;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* background-color: #f0f0ff; */
}

.account-field-contaiiner {
  width: 80%;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
}

.inner-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.field-outer-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 25px;
}
.inner-container {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 10px 10px 25px 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* background: #7464d22b; */
}
.img-info-container {
  border-radius: 5px;
  padding: 8px;
  background: #fdfdfd73;
  color: #2d3ab2;
  border: 1px solid #d0cfcf;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.profile-icon {
  width: 2em;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

span.header-span {
  color: #3f51b5;
}

.edit-account-outer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.account-button-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
  gap: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.tab-container {
  margin-bottom: 10px;
  margin-top: 15px;
}

.account-tab {
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.account-tab:hover {
  background-color: #c8c8ff78;
}

.active {
  background-color: #d0d0ffc4;
  font-weight: bold;
  color: #3134b5;
}

.img-upload-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

button.img-upload-button.css-110gi52 {
  /* background: linear-gradient(147deg, #2b8fff 0%, #e510ff 100%); */
  background: #008000de;
  color: #f1efef;
}

.profile-update span.MuiBadge-root.css-1c32n2y-MuiBadge-root {
  border: 1px solid #bcbcbc;
  border-radius: 50%;
}
