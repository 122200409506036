:root {
  --body__background: #ffffff;
  --header__color: #2b2929;
  --login__header: rgba(0, 0, 255, 0.774);
  --login__container_header: #000;
  --login__sideheading: #000;
  --file__upload_container: #fff;
  --dashed__border: #6860ee;
  --table__folder_container: #97939757;
  --table__folder_Color: #000;
  --TreeItem__background: rgb(157 194 231 / 42%);
  --TreeItem__color: #000;
  /* --TreeItem__Scrollbar: linear-gradient(#2f81fc4f, #a640faa1); */
  --TreeItem__Scrollbar_background: #fff;
  --Footer__background: #ddddddce;
  --Footer__color: #241f1f;
  --Footer__Inner_color: rgb(65, 63, 63);
  --ChatBot_Reply__background: #fff;
  --ChatBot_Reply__Color: #202123;
  --ChatBot_User_Color: #acacac52;
  --Skeleton__Background: #d6d6d6;
  --Skeleton__td_Color: rgb(0 0 0 / 11%);
  --Skeleton__File_Inner: #bfbfbf;
  --Skeleton__File_Background: #c7c7c78c;
}
[data-theme="dark"] {
  --body__background: #343541;
  --header__color: #f1ebeb;
  --login__header: white;
  --login__container_header: #f1ebeb;
  --login__sideheading: #d3cdcd;
  --file__upload_container: #202123;
  --dashed__border: rgb(56, 132, 255);
  --table__folder_container: #ffffff2e;
  --table__folder_Color: #000;
  --TreeItem__background: #fcf7f71e;
  --TreeItem__color: #000;
  /* --TreeItem__Scrollbar: linear-gradient(#216de0fb, #a649f3e8); */
  --TreeItem__Scrollbar_background: #343541;
  --Footer__background: #333;
  --Footer__color: #f3ededfb;
  --Footer__Inner_color: rgba(201, 198, 198, 0.911);
  --ChatBot_Reply__background: #202123;
  --ChatBot_Reply__Color: #fff;
  --ChatBot_User_Color: #8e8e8e57;
  --Skeleton__Background: #2e2f3a;
  --Skeleton__td_Color: rgb(255 255 255 / 50%);
  --Skeleton__File_Inner: #898a8e;
  --Skeleton__File_Background: #8989898c;
}
* {
  transition: all 0.3s ease-in-out;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
